import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {AuthState} from "../../action-state/states/auth.state";
import {DashboardService} from "../../services/dashboard.service";
import {FacebookService} from "../../services/facebook.service";
import {InAppPurchaseState} from "../../action-state/states/in-app-purchase.state";
import {InstagramService} from "../../services/instagram.service";
import {JoyrideService} from 'ngx-joyride';
import {LinkedinService} from "../../services/linkedin.service";
import {NgxSpinnerService} from "ngx-spinner";
import {PinterestService} from "../../services/pinterest.service";
import {SharedService} from "../../services/shared.service";
import {ShepherdService} from "angular-shepherd";
import {Store} from "@ngxs/store";
import {SubscriptionState} from "../../action-state/states/subscription.state";
import {TiktokService} from "../../services/tiktok.service";
import {TwitterService} from "../../services/twitter.service";
import {YoutubeService} from "../../services/youtube.service";
import {SnapchatService} from "../../services/snapchat.service";
import {ThreadsService} from "../../services/threads.service";
import { UpdateAccountPopup } from 'src/app/action-state/actions/auth.action';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-connect',
  templateUrl: './account-connect.component.html',
  styleUrls: ['./account-connect.component.less']
})
export class AccountConnectComponent implements OnInit,OnDestroy,AfterViewInit {

  @Input() isCreatePost = false;
  @Input() isTourActive = false;

  user = null;
  showFacebookModal = false;
  showInstagramModal = false;

  isLoadingConnection = false;
  loadingConnectionProvider = '';

  stripePlan = null;


  constructor(private dashboardService: DashboardService,
              public sharedService: SharedService,
              private twitterService: TwitterService,
              private linkedinService: LinkedinService,
              private facebookService: FacebookService,
              private instagramService: InstagramService,
              private youtubeService: YoutubeService,
              private tiktokService: TiktokService,
              private pinterestService: PinterestService,
              private snapchatService: SnapchatService,
              private threadsService: ThreadsService,
              private spinnerService: NgxSpinnerService,
              private store: Store,
              private _joyride: JoyrideService,
              private shepherdService: ShepherdService,
              private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    console.log("AccountConnectComponent ngOnInit isCreatePost : ", this.isCreatePost)
    if (this.isCreatePost) {
      localStorage.setItem('is_from_create_post', '1');
    } else {
      localStorage.removeItem('is_from_create_post');
    }


    this.user = this.store.selectSnapshot(AuthState).user;
    if (this.store.selectSnapshot(AuthState.user)?.free_user && this.store.selectSnapshot(AuthState.user)?.free_user !== null) {
      this.stripePlan = this.store.selectSnapshot(AuthState.user)?.free_user?.plan_id;
    } else if (this.store.selectSnapshot(SubscriptionState).subscription !== null) {
      this.stripePlan = this.store.selectSnapshot(SubscriptionState).subscription?.plan_id;
    } else if (this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null) {
      this.stripePlan = this.store.selectSnapshot(InAppPurchaseState).inAppPurchase?.plan_id;
    } else {
      this.stripePlan = null;
    }
  }

  ngOnDestroy() {
    this.isTourActive = false;
    this._joyride.closeTour();
  }

  ngAfterViewInit() {
    console.log("AccountConnectComponent ngAfterViewInit")
    if (this.isTourActive && this.sharedService.need_tour) {
      // this.initTour();
    }

  }

  initTour() {
    console.log("AccountConnectComponent initTour")
    setTimeout(() => {
      this._joyride.startTour({
          steps: ['dash_s2'],
          stepDefaultPosition: 'right',}).subscribe((step) => {
            console.log("AccountConnectComponent initTour step",step);
            document.body.id = step.name;
      }, (error) => {
      }, () => {
            document.querySelector('body').removeAttribute('id');
      });
    }, 500);

  }

  socialConnection(provider): void {
    if(this.user?.meta_data?.account_popup == 1){
      this.store.dispatch(new UpdateAccountPopup(0));
      let postData = {
        account_popup : 0
      }
      this.userService.updateImageCropPopup(postData).subscribe((res)=>{
        console.log("isImageCropPopup Response",res);
      },(error)=>{
        console.log("error while updating updateImageCropPopup",error);
      });
      
    }

    this.loadingConnectionProvider = provider;
    if (provider === this.sharedService.twitterProvider) {
      this.isLoadingConnection = true;
      this.twitterService.twitterConnect().subscribe((response) => {
        this.isLoadingConnection = false;
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.redirect_url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }
    if (provider === this.sharedService.linkedinProvider) {
      this.isLoadingConnection = true;
      this.linkedinService.linkedinConnect().subscribe((response) => {
        this.isLoadingConnection = false;
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }

    if (provider === this.sharedService.facebookProvider) {
      this.isLoadingConnection = true;
      this.facebookService.facebookRequestsToken().subscribe((response) => {
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }

    if (provider === this.sharedService.instagramProvider) {
      this.isLoadingConnection = true;
      this.instagramService.instagramRequestsToken().subscribe((response) => {
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }

    if (provider === this.sharedService.youtubeProvider) {
      this.isLoadingConnection = true;
      this.youtubeService.youtubeConnect().subscribe((response) => {
        this.isLoadingConnection = false;
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }

    if (provider === this.sharedService.tiktokProvider) {
      this.isLoadingConnection = true;
      this.tiktokService.tiktokConnect().subscribe((response) => {
        this.isLoadingConnection = false;
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }

    if (provider === this.sharedService.pinterestProvider) {
      this.isLoadingConnection = true;
      this.pinterestService.pinterestConnect().subscribe((response) => {
        this.isLoadingConnection = false;
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }
    if (provider === this.sharedService.snapchatProvider) {
      this.isLoadingConnection = true;
      console.log("Snapchat provider",provider);
      this.snapchatService.snapchatConnect().subscribe((response) => {
        this.isLoadingConnection = false;
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }

    if (provider === this.sharedService.threadsProvider) {
      this.isLoadingConnection = true;
      this.threadsService.threadsRequestsToken().subscribe((response) => {
        if (response.code === 200) {
          localStorage.setItem('error_display', '0');
          console.log("Response url",response.data.url)
          window.location.href = response.data.url;
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    }
  }

  twitterConnect(): void {
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.twitterProvider;
    console.log("twitterConnect stripePlan", this.stripePlan)
    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.twitterProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  linkedinConnect(): void {
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.linkedinProvider;
    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.linkedinProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  youtubeConnect(): void {

    this.isLoadingConnection = true;
    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.youtubeProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  pinterestConnect(): void {

    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.pinterestProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  tiktokConnect(): void {

    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.tiktokProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  facebookConnect(): void {
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.facebookProvider;

    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.facebookProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  instagramConnect(): void {

    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.instagramProvider;

    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.instagramProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  snapchatConnect(): void {
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.snapchatProvider;
    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.snapchatProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  threadsConnect(): void {
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.threadsProvider;

    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2' || this.stripePlan === 'plan_3') {
      this.socialConnection(this.sharedService.threadsProvider);
    } else {
      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');
    }
  }

  openFacebookModal() {
    // if (this.sharedService.need_tour && this.user.is_super_admin) {
    //   //this.shepherdService.complete();
    //   // this.sharedService.endTour();
    // }
    this._joyride.closeTour();
    this.showFacebookModal = true;
  }
  openInstagramModal(){
    this._joyride.closeTour();
    this.showInstagramModal = true;
  }
}
