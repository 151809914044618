import * as moment from 'moment';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject, debounceTime} from "rxjs";

import { AppWarningModalComponent } from '../app-warning-modal/app-warning-modal.component';
import { DateConvertPipe } from '../../pipes/date-time.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { IndexDbService } from '../../services/indexDb.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { SocialAccountService } from '../../services/social-account.service';
import {memoize} from "@ngxs/store/internals";

@Component({
  selector: 'app-preview-post',
  templateUrl: './preview-post.component.html',
  styleUrls: ['./preview-post.component.less'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewPostComponent implements OnInit,OnChanges,AfterViewInit,OnDestroy {
  @Input() content:any = [];
  @Input() images: any = [];
  @Input() mediaData: any = [];
  @Input() postMedias: any = [];
  @Input() galleryMediaData: any = [];
  @Input() image = '';
  @Input() name: any = '';
  @Input() time = 'Just now';
  @Input() type = '';
  @Input() profilePic = '';
  @Input() edit = false;
  @Input() delete = false;
  @Input() clone = false;
  @Input() id = null;
  @Input() comment: any = '';
  @Input() provider = null;
  deleted = false;
  @Input() linkData = null;
  @Input() validationErrors: any = [];
  @Input() title = '';
  @Input() accounts = '';
  @Input() userData: any = [];
  @Input() firstComment = '';
  @Input() audience = 'false';
  @Input() from = '';
  @Input() YTThumbnail = '';
  @Input() displayPlatformDetails = true;
  @Input() displayActions = true;
  @Input() isYoutubePost = false;
  @Input() postId: any = '';
  @Input() boardName:any = {};
  @Input() isCommentVisible = true;
  @Input() displayAction = false;
  @Input() post;
  @Input() instaReelThumbnail = '';
  @Input() selectedLocation = null;
  @Input() page = '';
  @Input() isFeedPost = false;
  @Input() isContentSubscribe : boolean = false;
  @Output() deleteChange = new Subject();

  displaySliderItem = true;
  dangerousVideoUrl: any;
  youtubeVideoUrl: any;
  isIframeLoaded = false;
  currentStoryIndex = 0;


  // instagram reels
  @Input() instagramType : string = 'feed_post'; // feed_post, reel_post
  @Input() autoPlay=false;

  //facebook story
  @Input() facebookType : string = 'feed_post'; // feed_post, reel_post


  // mention
  @Input() facebookMentions = [];
  @Input() instagramMentions = [];
  @Input() twitterMentions = [];
  @Input() threadsMentions = [];
  @Input() tiktokMentions = [];

  facebookContent:any = '';
  instagramContent:any = '';
  twitterContent:any = '';
  threadsContent:any = '';
  tiktokContent:any = '';
  linkedinContent:any = '';
  otherContent:any = '';

  availablePlatformForMention = [
    this.sharedService.facebookProvider, this.sharedService.instagramProvider,
    this.sharedService.twitterProvider, this.sharedService.threadsProvider,
    this.sharedService.tiktokProvider
  ];

  @Input() postErrors: any = [];
  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;

  effect = 'scrollx';

  isContentLoading = false;
  private contentChanged$ = new Subject<void>();

  @Input() multi_images: any = [];

  platformClass = {
    'INSTAGRAM' : 'post_instagram',
    'FACEBOOK' :  'post_facebook',
    'TWITTER' :  'post_twitter',
    'LINKEDIN' : 'post_linkedin',
    'YOUTUBE' :  'post_youtube',
    'TIKTOK' :   'post_tiktok',
    'PINTEREST' : 'post_pinterest',
    'THREADS' : 'post_twitter',
  };

  post_img_class = '';
  // isNzImageLoader:boolean = false;
  @Input() isSinglePlatformMode:boolean = false;
  @Input() shortUrls = []

  constructor(private router: Router,
    private modal: NzModalService,
    private spinnerService: NgxSpinnerService,
    private messageService: NzMessageService,
    private dateConvertPipe: DateConvertPipe,
    private socialAccountService: SocialAccountService,
    private nzImageService: NzImageService,
    private indexDbService: IndexDbService,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    console.log("previewPost ngOnInit")

    this.getYouTubeUrl();

    /*Youtube*/
    if((this.YTThumbnail==='' || this.YTThumbnail===undefined)  && this.post?.youtube_thumbnail!=''){
      this.YTThumbnail = this.post?.youtube_thumbnail
    }

    //Instagram Reel
    if((this.instaReelThumbnail==='' || this.instaReelThumbnail===undefined) && this.post?.instaReelThumbnail!=''){
      this.instaReelThumbnail = this.post?.instagram_reel_thumbnail
    }

    if(this.YTThumbnail==undefined){
      this.YTThumbnail = '';
    }

    if(this.instaReelThumbnail==undefined){
      this.instaReelThumbnail = '';
    }

    /*console.log("instaReelThumbnail 2: ",this.instaReelThumbnail);
    console.log("instaReelThumbnail : ",this.post);*/

    this.getUpdatedContent();

    this.multi_images = this.mediaData.concat(this.galleryMediaData);
    console.log("multi_images in ngOnIt",this.multi_images);

    this.post_img_class = this.getClass();
    if(this.isContentSubscribe){
      this.sharedService.content$.subscribe(updatedContent => {
        this.content = updatedContent;
        console.log("Updated Content:", this.content);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("previewPost ngOnChanges");
    //console.log("previewPost ngOnChanges postMedias",this.postMedias);
    
    // if (changes.postMedias && changes.postMedias.currentValue && changes.postMedias.previousValue && changes.postMedias.currentValue?.length !== changes.postMedias.previousValue?.length) {
    //   console.log("previewPost ngOnChanges postMedias");
    //   this.post_img_class = this.getClass();
    // }

    if (changes.content && changes.content.currentValue !== changes.content.previousValue) {
      console.log('Content has changed --->');
      const hasMentions = this.memoizedHasMentions(this.content);
      if(hasMentions){
        this.isContentLoading = true;
        this.contentChanged$.next();
      }else{
        this.getUpdatedContent();
      }
    }else if(changes.shortUrls){
      this.getUpdatedContent();
    }

  }

  ngAfterViewInit() {
    console.log("previewPost ngAfterViewInit")
    this.contentChanged$.pipe(
        debounceTime(300) // adjust the debounce time as needed
    ).subscribe(() => {
      //console.log("previewPost ngAfterViewInit subscribe-->")
      const hasMentions = this.memoizedHasMentions(this.content);
      console.log("previewPost ngAfterViewInit hasMentions",hasMentions)
      this.getUpdatedContent(hasMentions);
    });
  }

  ngOnDestroy(): void {
    this.contentChanged$.unsubscribe();
  }

  // Memoized functions
  private memoizedHasMentions = memoize((content) => {
    return content?.includes('@') || content?.includes('#');
  });
  
  getUpdatedContent(hasMentions=false){
    console.log("getUpdatedContent")
    this.content = this.sharedService.replaceUrlsWithShortened(this.content,this.shortUrls);
    this.linkedinContent = this.content;
    this.facebookContent = this.content;
    this.threadsContent = this.content;
    this.twitterContent = this.content;

    
    let urls = this.sharedService.extractUrls(this.content);
    if(urls.length!=0){
      
      let platforms = this.userData.map(item =>  item.provider);

      if(platforms.some(provider => [this.sharedService.facebookProvider,this.sharedService.linkedinProvider,this.sharedService.threadsProvider,this.sharedService.twitterProvider].includes(provider))){
        let hyperlinkedContent = this.sharedService.hyperLinkUrl(this.content,urls);
        platforms.map((platform)=>{
          if([this.sharedService.facebookProvider,this.sharedService.linkedinProvider,this.sharedService.threadsProvider,this.sharedService.twitterProvider].includes(platform)){
            if(platform === this.sharedService.facebookProvider){
              this.facebookContent = hyperlinkedContent;        
            }else if(platform === this.sharedService.linkedinProvider){
              this.linkedinContent = hyperlinkedContent;        
            }else if(platform === this.sharedService.threadsProvider){
              this.threadsContent = hyperlinkedContent;        
            }else if(platform === this.sharedService.twitterProvider){
              this.twitterContent = hyperlinkedContent;        
            }
          }
        });

      }

    }
    

    if (!hasMentions) {
      this.facebookContent = this.facebookContent;
      this.instagramContent = this.content;
      this.twitterContent = this.twitterContent;
      this.threadsContent = this.threadsContent;
      this.tiktokContent = this.content;
      this.otherContent = this.content;
      this.isContentLoading = false;
      return;
    }
    
    if (this.page == "create_post" || this.page == "create_post_new_flow") {
      let platforms = this.userData.map(item =>  item.provider);
      platforms.map((platform)=>{
        if(platform === this.sharedService.facebookProvider){
          this.facebookContent = this.sharedService.getPostContentByPlatform(this.facebookContent, this.sharedService.facebookProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
        }else if(platform === this.sharedService.instagramProvider){
          this.instagramContent = this.sharedService.getPostContentByPlatform(this.content, this.sharedService.instagramProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
        }else if(platform === this.sharedService.twitterProvider){
          this.twitterContent = this.sharedService.getPostContentByPlatform(this.twitterContent, this.sharedService.twitterProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
        }else if(platform === this.sharedService.threadsProvider){
          this.threadsContent = this.sharedService.getPostContentByPlatform(this.threadsContent, this.sharedService.threadsProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
        }else if(platform === this.sharedService.tiktokProvider){
          this.tiktokContent = this.sharedService.getPostContentByPlatform(this.content, this.sharedService.tiktokProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
        }
      });
      this.linkedinContent = this.sharedService.getPostContentByPlatform(this.linkedinContent, '', this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions,false);
      this.otherContent = this.sharedService.getPostContentByPlatform(this.content, '', this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions,false);

    } else {
      if (this.provider == this.sharedService.facebookProvider) {
        this.facebookContent = this.sharedService.getPostContentByPlatform(this.facebookContent, this.sharedService.facebookProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
      } else if (this.provider == this.sharedService.instagramProvider) {
        this.instagramContent = this.sharedService.getPostContentByPlatform(this.content, this.sharedService.instagramProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
      } else if (this.provider == this.sharedService.twitterProvider) {
        this.twitterContent = this.sharedService.getPostContentByPlatform(this.twitterContent, this.sharedService.twitterProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
      } else if (this.provider == this.sharedService.threadsProvider) {
        this.threadsContent = this.sharedService.getPostContentByPlatform(this.threadsContent, this.sharedService.threadsProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
      } else if (this.provider == this.sharedService.tiktokProvider) {
        this.tiktokContent = this.sharedService.getPostContentByPlatform(this.content, this.sharedService.tiktokProvider, this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions);
      } else {
        this.linkedinContent = this.sharedService.getPostContentByPlatform(this.linkedinContent, '', this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions,false);      
        this.otherContent = this.sharedService.getPostContentByPlatform(this.content, '', this.facebookMentions, this.instagramMentions, this.twitterMentions, this.threadsMentions, this.tiktokMentions,false);
      }
    }

    //setTimeout(()=>{
      this.isContentLoading = false;
      //this.cdr.markForCheck();
    //},150);
  }


  playReel(){
    this.instaReelThumbnail = '';
    this.autoPlay = true;
  }

  getClass(): string {
    console.log("preview getClass");
    let img_count = this.images.length;
    /*if(this.type==='multi'){
      img_count = this.mediaData.concat(this.galleryMediaData).filter((media) => media.file_type === 'image').length;
    }*/
    img_count = this.postMedias.length;

    if (img_count === 1) {
      return 'one_img';
    }
    if (img_count === 2) {
      return 'two_img';
    }
    if (img_count === 3) {
      return 'three_img';
    }
    if (img_count === 4) {
      return 'four_img';
    }
    if (img_count > 4) {
      return 'more_then_four_img';
    }
    return '';
  }

  getPlatformClass(platform): string {
    console.log("previewpost getPlatformClass")
    if (platform === this.sharedService.instagramProvider) {
      return 'post_instagram';
    }
    if (platform === this.sharedService.twitterProvider) {
      return 'post_twitter';
    }
    if (platform === this.sharedService.linkedinProvider) {
      return 'post_linkedin';
    }
    if (platform === this.sharedService.facebookProvider) {
      return 'post_facebook';
    }
    if (platform === this.sharedService.youtubeProvider) {
      return 'post_youtube';
    }
    if (platform === this.sharedService.tiktokProvider) {
      return 'post_tiktok';
    }
    if (platform === this.sharedService.pinterestProvider) {
      return 'post_pinterest';
    }
    if (platform === this.sharedService.threadsProvider) {
      return 'post_twitter';
    }
    return '';
  }

  editPost(id): void {
    this.modal.closeAll();
    this.router.navigate(['/', 'post', 'edit-post', id]);
  }

  deletePost(id): void {
    this.modal.confirm({
      nzTitle: 'Delete post?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzComponentParams: {
        message: "Are you sure you want to delete this post?"
      },
      nzOkText: 'Yes, Delete It',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCentered: true,
      nzAutofocus: null,
      nzIconType: null,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        //this.spinnerService.show();
        this.socialAccountService.deletePost(id).subscribe(res => {
          //this.spinnerService.hide();
          if (res.code === 200) {
            if (res.toast === true) {
              this.deleteChange.next({deleted:true});
              this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);

            }
            this.deleted = true;
            this.modal.closeAll();
          }

        }, error => {
          //this.spinnerService.hide();
          this.modal.closeAll();
        });
      }
    });
  }

  checkTime(): boolean {
    const time2 = this.dateConvertPipe.transform(this.time);
    const cur = moment().format('MM/DD/yyyy hh:mm A');
    if (moment(time2).diff(cur, 'minutes') > 15) {
      return true;
    }
    return false;
  }


  /*preview(i): void {
    if (i === 4 && this.images.length > 4) {
      const images: any = [];
      this.spinnerService.show();
      this.images.forEach(async (item) => {
        let url = await this.indexDbService.getPresignedImageUrl(item);
        if (url === undefined || url === null) {
          url = await this.indexDbService.getPresignedImageUrl(item);
        }
        images.push({ src: url });
      });
      this.spinnerService.hide();
      console.log(images,"imagesimages");

      this.nzImageService.preview(images);
    }
  }*/

  preview(i: number): void {
    if (i === 4 && this.postMedias.length > 4) {
      const images: any[] = [];
      // this.isNzImageLoader = true;
      
      const promises = this.postMedias.map(async (item) => {
        if (item.file_type === 'image') {
          let url = await this.indexDbService.getPresignedImageUrl(item.media_file);
          if (url === undefined || url === null) {
            url = await this.indexDbService.getPresignedImageUrl(item.media_file);
          }
          images.push({ src: url });
        }
      });
    
      Promise.all(promises)
        .then(() => {
          // this.isNzImageLoader = false; // End loading
          console.log(images, "imagesimages");
          this.nzImageService.preview(images);
        })
        .catch((error) => {
          // this.isNzImageLoader = false; // Ensure loading stops even on error
          console.error("Error processing images:", error);
        });
    }
  }
    



  clonePost(id): void {
    this.modal.closeAll();
    this.router.navigate(['/', 'post', 'create-post'], {
      queryParams: {
        type: 'post', reference: id
      }
    });
  }

  previewSliderChanges(event) {
    this.displaySliderItem = false;
    setTimeout(() => {
      this.displaySliderItem = true;
    }, 10);
  }

  getYouTubeUrl() {
    this.dangerousVideoUrl = "https://www.youtube.com/embed/" + this.postId+"?autoplay=1";
    this.youtubeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoUrl);
  }

  playYTVideo(){
    //console.log('YTThumbnail',this.YTThumbnail);
    this.YTThumbnail = '';
    this.autoPlay = true;
  }

  onInstaStoryChange(event){
    this.currentStoryIndex = event;
  }

  get filteredPostMedias() {
    return this.postMedias.filter(item => item.file_type === 'image');
  }



}
