
export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: any, public token: string) { }
}

export class AutoLogin {
  static readonly type = '[Auth] Auto Login';

  constructor(public payload: any) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class ChangePassword {
  static readonly type = '[Auth] Change Password';

  constructor(public payload: any) { }
}

export class UpdateProfile {
  static readonly type = '[User] Update Profile';

  constructor(public payload: any) { }
}

export class UpdateUser {
  static readonly type = '[Auth] Update User';

  constructor(public payload: any) { }
}

export class UpdateImageCropPopup {
  static readonly type = '[Auth] Update Image Crop Popup';

  constructor(public payload: any) { } 
}

export class UpdateAccountPopup {
  static readonly type = '[Auth] Update Account Popup';

  constructor(public payload: any) { } 
}
