import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import {BulkScheduleGuard} from "./guards/bulk-schedule.guard";
import { FacebookRedirectAuthComponent } from './pages/facebook-redirect-auth/facebook-redirect-auth.component';
import { InactiveOrganizationComponent } from './pages/auth/inactive-organization/inactive-organization.component';
import { InactiveOrganizationGuard } from './guards/inactive-organization.guard';
import { InstagramRedirectAuthComponent } from './pages/instagram-redirect-auth/instagram-redirect-auth.component';
import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { RedirectAuthComponent } from './pages/redirect-auth/redirect-auth.component';
import { SubscriptionComponent } from './pages/auth/subscription/subscription.component';
import { SubscriptionGuard } from './guards/subscription.guard';
import {SubscriptionTeamGuard} from "./guards/subscriptionteam.guard";
import { contentRoutes } from './shared/routes/content.routes';

// import { SubscriptionComponent } from './pages/auth/subscription/subscription.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  /*{
    path: '',
    children: contentRoutes,
    component: LayoutComponent,
  },*/
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'join-our-team',
    loadChildren: () =>
      import('./pages/invite-accept/invite-accept.module').then(
        (m) => m.InviteAcceptModule
      ),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        data : { title: "Dashboard" }
      },
      {
        path: 'feed',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/socialfeed/socialfeed.module').then(
            (m) => m.SocialfeedModule
          ),
        data : { title: "Social Feed" }
      },
      // {
      //   path: 'posts',
      //   //canActivate: [AuthGuard],
      //   loadChildren: () => import('./pages/post/post.module').then(m => m.PostModule)
      // },
      {
        path: 'post',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/manage-post/manage-post.module').then(
            (m) => m.ManagePostModule
          ),
        data:{ title: "Posts", name : "Posts"}
      },
      {
        path: 'accounts',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/accounts/account.module').then(
            (m) => m.AccountModule
          ),
        data : { title:"Accounts" }
      },
      {
        path: 'calendar',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/calendar/calendar.module').then(
            (m) => m.CalendarModule
          ),
        data:{ title:"Calendar", 'name' : "Calendar"}
      },
      {
        path: 'inbox',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/inbox/inbox.module').then((m) => m.InboxModule),
        data:{ title:"Messages", 'name' : "Messages"}
      },
      {
        path: 'analytics',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/analytics/analytics.module').then(
            (m) => m.AnalyticsModule
          ),
        data:{ title:"Analytics", 'name' : "Analytics"}
      },
      {
        path: 'organization',
        canActivate: [SubscriptionGuard,SubscriptionTeamGuard],
        loadChildren: () =>
          import('./pages/organization/organization.module').then(
            (m) => m.OrganizationModule
          ),
        data : { title:"Organization" }
      },
      {
        path: 'settings',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        data:{ title:"Settings", 'name' : "Settings"}
      },
      {
        path: 'notifications',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/notification/notification.module').then(
            (m) => m.NotificationModule
          ),
        data:{ title:"Notifications", 'name' : "Notifications"}
      },
      {
        path: 'redirect-auth',
        canActivate: [SubscriptionGuard],
        component: RedirectAuthComponent,
        data : { title:"Redirecting" }
      },
      {
        path: 'facebook-redirect-auth',
        canActivate: [SubscriptionGuard],
        component: FacebookRedirectAuthComponent,
        data : { title:"Redirecting" }
      },
      {
        path: 'instagram-redirect-auth',
        canActivate: [SubscriptionGuard],
        component: InstagramRedirectAuthComponent,
        data : { title:"Redirecting" }
      },
      {
        path: 'gallery',
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
          import('./pages/gallery-page/gallery.module').then(
            (m) => m.GalleryModule
          ),
        data : { title:"Gallery" }
      },
      {
        path: 'subscription',
        //canActivate: [SubscriptionGuard], Do not active this guard here
        component: SubscriptionComponent,
        data : { title:"Subscription" }
      },
      {
        path: 'bulk-schedule',
        //canActivate: [BulkScheduleGuard],
        loadChildren: () => import('./pages/bulk-schedule/bulk-schedule.module').then(m => m.BulkScheduleModule),
        data : { title:"Bulk Schedule" }
      },
      {
        path: 'inactive-organization',
        component: InactiveOrganizationComponent,
        canActivate:[InactiveOrganizationGuard],
        data : { title:"In Active Organization" }
      },
      {
        path: 'campaigns',
        canActivate: [SubscriptionGuard],
        loadChildren: () => import('./pages/campaigns/campaigns.module').then(m => m.CampaignsModule),
        data:{ title:"Campaigns",'name' : "Campaigns"},
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
