export const Constants = {
  ERROR_MSG: 'Something went wrong! Please try again later'
};

export const general = {
  current_api_version: 'v1'
};

export const APIPATH = {
  // analytics
  social_account_analytics: 'social-account-analytics',
  get_twitter_posts: 'get_twitter_posts',
  get_twitter_posts_analytics:'get_twitter_posts_analytics',
  twitter_audience_growth_chart_data: 'twitter-audience-growth-chart-data',
  get_social_media_posts: 'get_social_media_posts',
  get_social_audience: 'get_social_audience',
  get_dashboard_audience: 'get_dashboard_audience',
  get_social_insights: 'get_social_insights',
  get_twitter_social_insights: 'get_twitter_social_insights',
  get_total_post_count_dashboard: 'get_total_post_count_dashboard',
  get_social_ad_campaign: 'get_social_ad_campaign',

  // auth
  plans: 'plans',
  register: 'register',
  login: 'login',
  mobile_login: 'login-with-id',
  forgot_password: 'forgot-password',
  reset_password: 'reset-password',
  authenticate: 'authenticate',
  logout: 'logout',
  verify_email: 'verify-email',
  resend_verify_email_link: 'resend-verify-email-link',
  reject_invitation: 'reject-invitation',
  accept_invitation: 'accept-invitation',
  otp_verify: 'otp-verify',
  otp_verify_change_password: 'otp-verify-change-password',
  user_check: 'user-check',
  save_token: 'save_token',
  get_organisation_subscription:'get-organisation-subscription',
  is_user_active:'is-user-active',

  // bulk schedule
  bulk_post_schedule_import: 'bulk-post-schedule-import',
  bulk_post_schedule_status: 'bulk-post-schedule-status',
  bulk_schedule_create_post: 'bulk-schedule-create-post',
  bulk_schedule_edit_post: 'bulk-schedule-edit-post',
  bulk_schedule_delete_post: 'bulk-schedule-delete-post',
  bulk_schedule_cancel: 'bulk-schedule-cancel',

  // facebook
  facebook_request_token: 'facebook-request-token',
  facebook_auth: 'facebook-auth',
  save_facebook_accounts: 'save-facebook-accounts',
  get_facebook_accounts: 'get-facebook-accounts',
  facebook_ad_accounts: 'facebook_ad_accounts',
  facebook_countries: 'facebook_countries',
  facebook_reach: 'facebook_reach',
  facebookAd_create: 'facebookAd_create',
  facebookAd_update: 'facebookAd_update',
  facebookAd_change_status: 'facebookAd_change_status',
  facebook_posts: 'facebook_posts',
  facebook_targeting_search: 'facebook/targeting_search',
  facebook_ad_preview: 'facebook/ad_preview',
  facebook_get_campaigns: 'facebook/get_campaigns',
  facebook_delete_campaign: 'facebook/delete_campaign',

  facebook_location: 'facebook_location',
  facebook_page_search: 'facebook_page_search',

  // instagram
  instagram_request_token: 'instagram-request-token',
  instagram_auth: 'instagram-auth',
  save_instagram_accounts: 'save-instagram-accounts',
  get_instagram_accounts: 'get-instagram-accounts',
  instagram_posts: 'instagram_posts',

  // linkedin
  linkedin_request_token: 'linkedin-request-token',
  linkedin_auth: 'linkedin-auth',
  save_linkedin_accounts: 'save-linkedin-accounts',
  get_linkedin_accounts: 'get-linkedin-accounts',
  linkedin_posts: 'linkedin_posts',
  linkedin_ad_accounts: 'linkedin_ad_accounts',
  linkedin_countries:'linkedin-countries',
  linkedin_reach:'linkedin-reach',
  linkedin_ad_create:'linkedin-ad-create',
  linkedin_ad_update:'linkedin-ad-update',
  linkedin_get_campaigns:'linkedin-get-campaigns',
  linkedin_ad_change_status:'linkedin-ad-change-status',
  linkedin_ad_preview:'linkedin-ad-preview',

  //snapchat
  snapchat_request_token: 'snapchat-request-token',
  snapchat_auth: 'snapchat-auth',
  snapchat_ad_accounts: 'snapchat-ad-accounts',
  snapchat_countries: 'snapchat-countries',
  snapchat_languages: 'snapchat-languages',
  snapchat_reach: 'snapchat-reach',
  snapchat_ad_create: 'snapchat-ad-create',
  snapchat_ad_update: 'snapchat-ad-update',
  snapchat_ad_change_status: 'snapchat-ad-change-status',
  snapchat_campaign_delete: 'snapchat-campaign-delete',
  snapchat_get_campaigns: 'snapchat-get-campaigns',

  // Threads
  threads_request_token: 'threads-request-token',
  threads_auth: 'threads-auth',
  threads_authentication: 'threads-authentication',

  // social
  get_social_account: 'social-account-get',
  change_status_social_account: 'change-status-social-account',
  social_active_account_get: 'social-active-account-get',
  get_analytics_account: 'get-analytics-account',
  delete_social_account: 'delete-social-account',
  create_post: 'create-post',
  create_post_platform_wise: 'create-post-by-platform',
  check_post_validation: 'check-post-validation',
  edit_post: 'edit-post',
  get_delivered_post: 'get-delivered-post',
  get_undelivered_post: 'get-undelivered-post',
  get_draft_post: 'get-draft-post',
  get_queue_post: 'get-queue-post',
  // get_post_count:'get-post-count',
  post_count_per_day: 'post-count-per-day',
  // get_post_count_calendar:'get-post-count-calendar',
  get_schedule_post: 'get-schedule-post',
  delete_post: 'delete-post',
  approve_draft_post: 'approve-draft-post',
  reject_draft_post: 'reject-draft-post',
  get_calendar_posts:'get-calendar-posts',
  get_month_post: 'get-month-post',
  get_week_post: 'get-week-post',
  get_daily_post: 'get-daily-post',
  get_post: 'get-post',
  social_active_account_by_role: 'social-active-account-by-role',
  bulk_post_social_accounts: 'bulk-post-social-accounts',
  get_posts: 'get_posts',
  get_assignee_users: 'get_assignee_users',
  auto_hashtag: 'auto_hashtag',
  get_article_settings: 'get_article_settings',
  get_articles: 'get_articles',
  edit_post_index: 'edit_post_index',
  short_urls: "short-urls",

  // twitter
  request_token: 'request-token',
  authenticated_requests: 'authenticated-requests',
  get_twitter_accounts: 'get-twitter-accounts',
  reconnect_twitter_account: 'reconnect-twitter-account',
  get_pinned_and_last_tweet: 'twitter/get-pinned-and-last-tweet',
  get_twitter_profile_data: 'twitter/get-twitter-profile-data',
  unfollow_user: 'twitter/unfollow-user',
  follow_user: 'twitter/follow-user',
  get_tweets_by_token: 'twitter/get-tweets-by-token',
  like_tweet: 'twitter/like-tweet',
  unlike_tweet: 'twitter/unlike-tweet',
  get_twitter_account_profile: 'twitter/get-twitter-account-profile',
  get_user_liked_tweet: 'twitter/get-user-liked-tweet',
  get_user_liked_tweet_by_token: 'twitter/get-user-liked-tweet-by-token',
  get_tweet_status: 'twitter/get-tweet-status',
  search_people: 'twitter/search-people',
  remove_ignored_user: 'twitter/remove-ignored-user',

  // user
  update_profile: 'update-profile',
  get_Post_Count_dashboard: 'get-post-count-dashboard',
  change_password: 'change-password',
  subscribe: 'subscribe',
  upgrade_subscribe_plan: 'upgrade-subscribe-plan',
  add_card: 'add-card',
  make_card_default: 'make-card-default',
  delete_card: 'delete-card',
  get_user_save_card: 'get-user-save-card',
  get_social_accounts_list: 'get-social-accounts-list',
  delete_account: 'delete-account',
  cancel_subscription: 'cancel-subscription',
  cancel_downgrade_subscription: 'cancel-downgrade-subscription',
  payment_intent: 'payment-intent',
  get_top_notification: 'get-top-notification',
  set_notification_count: 'set-notification-count',
  get_all_notification: 'get-all-notification',
  notification_setting: 'notification-setting',
  get_notification_setting: 'get-notification-setting',
  manage_membership: 'apple/manage-membership',
  get_plan_details: 'get-plan-details',
  apply_coupon: 'apply_coupon',
  contact: 'contact',
  end_tour: 'end_tour',
  end_team_tour: 'end_team_tour',
  get_addon: 'get_addon',
  buy_addon: 'buy_addon',
  get_transaction_history: 'get-transaction-history',
  update_image_crop_popup: 'update-image-crop-popup',

  // user gallery
  get_user_gallery: 'get-user-gallery',
  get_user_gallery_old: 'get-user-gallery-old',
  delete_gallery_media: 'delete-gallery-media',
  upload_media_to_gallery: 'upload-media-to-gallery',
  delete_gallery_media_cancel: 'delete-gallery-media-cancel',
  create_album: 'create-album',
  get_album_list: 'get-album-list',
  album_delete: 'album-delete',
  add_Media_to_album: 'add-Media-to-album',
  is_favorite: 'is-favorite',
  get_isFavorite_gallery: 'get-isFavorite-gallery',
  allImageFromAlbum: 'all-image-album',
  remove_media_from_album:'remove-media-from-album',

  add_to_favorite:'add_to_favorite',//new apis
  get_albums: 'get_albums',
  generate_images: 'generate_images',
  generate_image_variant: 'generate_image_variant',
  generate_hashtags: 'generate_hashtags',
  generate_video_hashtags: 'generate_video_hashtags',

  // youtube
  youtube_request_token: 'youtube-request-token',
  youtube_auth: 'youtube-auth',
  save_youtube_accounts: 'save-youtube-accounts',
  get_youtube_accounts: 'get-youtube-accounts',
  reconnect_youtube_account: 'reconnect-youtube-account',
  get_youtube_categories: 'get-youtube-categories',

  // team
  organization_list: 'organization-list',
  super_admin_org: 'super-admin-org',
  team_list: 'team-list',
  save_organization: 'save-organization',
  save_team: 'save-team',
  check_member: 'check-member',
  save_invitation: 'save-invitation',
  organization_social_networks: 'organization-social-networks',
  organization_members: 'organization-members',
  team_social_networks: 'team-social-networks',
  team_members: 'team-members',
  verify_invite_token: 'verify-invite-token',
  register_team_member: 'register-team-member',
  delete_member: 'delete-member',
  member_detail: 'member-detail',
  delete_team_social_account: 'delete-team-social-account',
  update_invitation: 'update-invitation',
  delete_team: 'delete-team',
  view_page_member: 'view-page-member',
  invited_user_list: 'invited-user-list',
  resend_user_invitation: 'resend-user-invitation',
  delete_draft_post: 'delete-draft-post',
  delete_invitation:'delete-invitation',
  permissions: 'permissions',
  save_permissions: 'save_permissions',
  team_details: 'team_details',
  delete_from_team: 'delete_from_team',
  get_user_permission: 'get_user_permission',
  add_to_team: 'add_to_team',
  get_user_social_profiles: 'get_user_social_profiles',
  update_user_social_access: 'update_user_social_access',
  all_org_member:'all-org-member',
  all_organization_member:'all-organization-member',
  get_assigned_posts: 'get_assigned_posts',

  // tiktok
  tiktok_request_token: 'tiktok-request-token',
  tiktok_auth: 'tiktok-auth',
  get_tiktok_accounts: 'get-tiktok-accounts',
  reconnect_tiktok_account: 'reconnect-tiktok-account',
  get_tiktok_creator_info: 'get-tiktok-creator-info',

  // pinterest
  pinterest_request_token: 'pinterest-request-token',
  pinterest_auth: 'pinterest-auth',
  get_pinterest_accounts: 'get-pinterest-accounts',
  reconnect_pinterest_account: 'reconnect-pinterest-account',
  get_pinterest_boards: 'get-pinterest-boards',
  create_pinterest_board: 'create-pinterest-board',
  delete_board: 'delete-board',

  // Inbox
  get_conversations: 'get_conversations',
  load_more_conversations: 'load_more_conversations',

  //facebook
  send_fb_message: 'send-fb-message',
  mark_message_as_seen: 'mark-message-as-seen',
  get_facebook_posts: 'get-facebook-posts',
  add_comment_facebook_post: 'add-comment-facebook-post',
  like_comment_facebook_post: 'like-comment-facebook-post',
  delete_facebook_comment: 'delete-facebook-comment',
  mark_comment_as_seen: 'mark-comment-as-seen',
  mark_as_resolve: 'mark_as_resolve',

  //instagram
  send_instagram_message: 'send-instagram-message',
  add_comment_instagram_post: 'add-comment-instagram-post',
  delete_instagram_comment: 'delete-instagram-comment',
  inbox_social_accounts: 'inbox-social-accounts',
  instagram_mark_message_as_seen: 'instagram-mark-message-as-seen',

  //linkedin
  add_comment_linkedin_post: 'add-comment-linkedin-post',
  delete_linkedin_comment: 'delete-linkedin-comment',
  linkedin_mark_comment_as_seen: 'linkedin_mark_comment_as_seen',

  //upload to wasabi
  upload_media: 'upload_media',
  upload_post_media: 'upload_post_media',

  facebook_feed: 'facebook/feed',

  get_feeds: 'feeds',
  get_hashtags: 'instagram/hashtags',
  add_hashtag: 'instagram/hashtags/add',
  delete_hashtag: 'instagram/hashtags/delete',
  get_platform_accounts: 'get_platform_accounts',

  // Dashboard
  dashboard: 'dashboard',
  get_twitter_feeds: 'get_twitter_feeds',
  get_recent_and_schedule_posts: 'get_recent_and_schedule_posts',

  share_post: 'share_post',
  undo_retweet: 'undo_retweet',
  get_dashboard_engagement: 'get_dashboard_engagement',
  mark_as_read_notification: 'mark_as_read_notification',
  delete_notifications: 'delete_notifications',

  // Hashtag & template
  create_template:'create-template',
  get_all_template:'get-all-template',
  delete_template:'delete-template',
  update_template:'update-template'
};
