import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialAccountService {

  public twitterProvider = 'TWITTER';
  public linkedinProvider = 'LINKEDIN';
  public facebookProvider = 'FACEBOOK';
  public instagramProvider = 'INSTAGRAM';
  public youtubeProvider = 'YOUTUBE';
  public tiktokProvider = 'TIKTOK';
  public pinterestProvider = 'PINTEREST';

  public allSocialPlatforms = [
    this.twitterProvider,
    this.linkedinProvider,
    this.facebookProvider,
    this.instagramProvider,
    this.youtubeProvider,
    this.tiktokProvider,
    this.pinterestProvider
  ];

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  getSocialAccount(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_social_account'));
  }

  changeSocialAccountStatus(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('change_status_social_account'), postValue);
  }

  getSocialActiveAccount(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('social_active_account_get'));
  }

  getSocialActiveAccountByRole(team_id='0',create_post = false,is_campaign = false): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('social_active_account_by_role')+"?team_id="+team_id+"&create_post="+create_post+"&is_campaign="+is_campaign);
  }

  getSocialActiveAccountByTeam(team_ids): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('social_active_account_get')+'?team_ids='+team_ids);
  }

  deleteSocialAccount(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_social_account'), postValue);
  }

  createPost(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('create_post'), postValue);
  }

  updatePost(id, postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('edit_post') + '/' + id, postValue);
  }

  getDeliveredPost(page, startDate, endDate, accounts,limit): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_delivered_post') + '?page=' + page + '&start_date=' + startDate + '&end_date=' + endDate + '&selected_accounts=' + accounts +'&limit='+limit);
  }

  getUndeliveredPost(page, startDate, endDate, accounts,limit): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_undelivered_post') + '?page=' + page + '&start_date=' + startDate + '&end_date=' + endDate + '&selected_accounts=' + accounts+'&limit='+limit);
  }

  getDraftPost(page, startDate, endDate, accounts,limit): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_draft_post') + '?page=' + page + '&start_date=' + startDate + '&end_date=' + endDate + '&selected_accounts=' + accounts+'&limit='+limit);
  }

  getQueuePost(page, startDate, endDate, accounts,limit): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_queue_post') + '?page=' + page + '&start_date=' + startDate + '&end_date=' + endDate + '&selected_accounts=' + accounts+'&limit='+limit);
  }

  getPostCount(postValue) {
    return this.http.post(this.helper.createAPIUrl('get_post_count') , postValue);
  }

  approveDraftPost(id, postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('approve_draft_post') + '/' + id, postValue);
  }

  rejectDraftPost(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('reject_draft_post') + '/' + id);
  }

  getSchedulePost(page, timezone, startDate, endDate, accounts,limit): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_schedule_post') + '?page=' + page + '&timezone=' + timezone + '&start_date=' + startDate + '&end_date=' + endDate + '&selected_accounts=' + accounts+'&limit='+limit);
  }

  getPosts(type, page, timezone, startDate, endDate, accounts,user_ids,limit): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_posts') + '?type='+type +'&page=' + page + '&timezone=' + timezone + '&start_date=' + startDate + '&end_date=' + endDate + '&selected_accounts=' + accounts+'&user_ids='+user_ids+'&limit='+limit);
  }

  deletePost(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('delete_post') + '/' + id);
  }

  // getMonthPost(postValue): Observable<any> {
  //   return this.http.post(this.helper.createAPIUrl('get_month_post'), postValue);
  // }

  getPostDetail(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_post') + '/' + id);
  }

  getSocialMediaImage(provider: string) {
    let img = '';
    if (this.twitterProvider === provider) {
      img = '../../../assets/images/providers/medium/TWITTER.png';
    } else if (this.linkedinProvider === provider) {
      img = '../../../assets/images/providers/medium/LINKEDIN.png';
    } else if (this.facebookProvider === provider) {
      img = '../../../assets/images/providers/medium/FACEBOOK.png';
    } else if (this.instagramProvider === provider) {
      img = '../../../assets/images/providers/medium/INSTAGRAM.png';
    } else if (this.youtubeProvider === provider) {
      img = '../../../assets/images/providers/medium/YOUTUBE.png';
    }

    return img;
  }

  getSocialMediaIcon(provider: string) {
    let icon = '';
    if (this.twitterProvider === provider) {
      icon = 'twitter';
    } else if (this.linkedinProvider === provider) {
      icon = 'linkedin';
    } else if (this.facebookProvider === provider) {
      icon = 'facebook';
    } else if (this.instagramProvider === provider) {
      icon = 'instagram';
    } else if (this.youtubeProvider === provider) {
      icon = 'youtube';
    } else if (this.tiktokProvider === provider) {
      icon = 'tiktok';
    } else if (this.pinterestProvider === provider) {
      icon = 'pinterest';
    }

    return icon;
  }

  inboxSocialAccounts(searchText): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('inbox_social_accounts')+'?search='+searchText);
  }

  bulkPostSocialAccount(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('bulk_post_social_accounts'));
  }

  // deleteBoard(postValue){
  //   return this.http.post(this.helper.createAPIUrl('delete_board'), postValue);
  // }
  // getWeekPost(postValue): Observable<any> {
  //   return this.http.post(this.helper.createAPIUrl('get_week_post'), postValue);
  // }
  // getDailyPost(postValue): Observable<any> {
  //   return this.http.post(this.helper.createAPIUrl('get_daily_post'), postValue);
  // }

  getAssigneeUsers(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_assignee_users'), postValue);
  }

  getAutoHashtagText(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('auto_hashtag'), postValue);
  }

  getCalenderPosts(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_calendar_posts'), postValue);
  }

  getArticleSettings(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_article_settings'));
  }

  getArticles(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_articles'), postValue);
  }

  editPostIndex(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('edit_post_index'), postValue);
  }

  getFacebookLocation(search='',id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_location')+"?search="+search+"&id="+id);
  }

  getFacebookPageSearch(search='',id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_page_search')+"?search="+search+"&id="+id);
  }

  createPostPlatformWise(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('create_post_platform_wise'), postValue);
  }
  
  checkPostValidation(postValue): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('check_post_validation'), postValue);
  }

  getShortUrls(postValue): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('short_urls'), postValue);
  }
}
