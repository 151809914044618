<div class="plan-wrapper">

  <div class="title-sec" *ngIf="!isLogin">
    <h1>60 Days Free - No Credit Card Required.</h1>
    <h2>Choose a plan that works for you</h2>
    <p>Select the plan that best suits your needs. You can change your plan anytime.</p>
  </div>

  <div class="plan-block-wrapper">
    <div class="plan-block-tab">
      <ul>
        <li>
          <a href="javascript:;" (click)="changePlan('monthly')"
             [class.active]="subscriptionType === 'monthly'">
            <p>Monthly</p>
          </a>
        </li>
        <li>
          <a href="javascript:;" (click)="changePlan('annual')"
             [class.active]="subscriptionType === 'annual'">
            <p>Annual</p>
            <span>Save {{discountPercentage}}%</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="plan-content">
      <div class="plan-block">

        <ng-container *ngIf="isPlanLoading && planDetails?.length === 0">
          <ng-container *ngFor="let item of dummyItems; let i = index">
            <div class="plan-block-in">
              <nz-skeleton *ngFor="let item of dummyItems"
                           [nzAvatar]="false" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isPlanLoading">
          <ng-container *ngFor="let plan of planDetails; let i = index">
            <div class="plan-block-in">
              <div class="plan-block-hdr-main">
                <div class="plan-block-hdr">
                  <div class="hdr-left">
                    <div class="icon-sec">
                      <img src="../../../../assets/images/launch-icon.svg" alt="" *ngIf="i===0">
                      <img src="../../../../assets/images/professional-icon.svg" alt="" *ngIf="i===1">
                    </div>
                    <div class="content-sec">
                      <span>{{ plan['for'] }}</span>
                      <p>{{ plan['planType'] | titlecase }}</p>
                    </div>
                  </div>
                </div>
                <div class="hdr-dec">
                  <p>{{ plan['plan_description'] }}</p>
                </div>
              </div>

              <span *ngIf="subscriptionType === 'monthly'">
                 <div class="plan-price">
                   <h1> ${{ plan['amount'] }}<sub>/month</sub></h1>
                   <!-- <p>
                     <span class="discount-price">${{ (plan['amount'] * 12).toFixed(2) }}/year</span>billed monthly
                   </p> -->
                   <p>&nbsp;</p>
                 </div>
              </span>

              <span *ngIf="subscriptionType === 'annual'">
                <div class="plan-price">
                  <h1>${{ (plan['amount'] / 12).toFixed(2) }}<sub>/month</sub></h1>
                  <p>
                    <span class="actual-price">${{ (plan['amount'] / (1 - (discountPercentage / 100))).toFixed(2) }}</span>
                    <span class="discount-price">$ {{ plan['amount'] }}/year</span>billed annually
                  </p>
                </div>
              </span>

              <div class="plan-selection">

                <ng-container *ngIf="!isLogin">
                   <button class="btn_primary" (click)="selectPlanForRegister(plan)">
                    Select Plan
                  </button>
                </ng-container>

                <ng-container *ngIf="isLogin">
                    <button class="btn_primary" *ngIf="subscription === null" (click)="selectPlan(plan['plan_id'], plan['planType'], plan['amount'], plan['plan'],false)">
                      <ng-container *ngIf="isFreeTrialSubscribing && selectedPlanId==plan['plan_id']">
                        <span nz-icon nzType="loading"></span>
                      </ng-container>
                      {{(subscriptionCancelAtOnce || inAppPurchaseCancelAtOnce)  ? 'Subscribe to ' + plan['planType'] + ' Plan' : 'Start Your ' +
                      plan['trial'] + ' Days Free Trial'}}
                    </button>

                    <button class="btn_primary" *ngIf="hideorshowButton()" [disabled]="((subscription !== null && subscription.plan_id === plan['plan'] && subscription.plan_type === plan['type'])) || selectPlanLoading"
                            (click)="selectPlan(plan['plan_id'], plan['planType'], plan['amount'], plan['plan'])">
                      <span *ngIf="(subscription !== null && (subscription.plan_id !== plan['plan'] || subscription.plan_type !== plan['type']))">
                          {{getText(plan['plan']) + ' To ' + plan['planType'] | titlecase}}
                      </span>
                      <span *ngIf="(subscription !== null && subscription.plan_id === plan['plan'] && subscription.plan_type === plan['type']) ">
                        Current Plan
                      </span>
                    </button>
                </ng-container>

                <ng-container *ngIf="subscription !== null && subscription.plan_id === plan['plan'] && subscription.plan_type === plan['type'] && subscription.is_trial ">
                  <button *ngIf="subscriptionType === 'annual' && !isTrailWeekPassed" class="btn_primary btn_discount"
                          (click)="selectPlan(plan['plan_id'], plan['planType'], plan['amount'], plan['plan'],false,true)">
                    {{ sharedService.skipFreeTrialText }}
                  </button>
                </ng-container>

              </div>
              <div class="plan-description">
                <p>{{ plan['title'] }}</p>
                <div class="includes-point">
                  <div class="includes-point-title">
                    <!--<span>{{ plan['planType'] | titlecase }} includes:</span>-->
                    <span *ngIf="i===0">{{ plan['planType'] | titlecase }} includes:</span>
                    <span *ngIf="i===1">{{ plan['planType'] | titlecase }}
                      <br/>All {{ planDetails[0]['planType'] }} plan features,
                      <br/>as well as:
                    </span>
                  </div>
                  <div class="includes-point-list">
                    <ul>
                      <li *ngFor="let des of plan['description'], let i = index">
                        <span [innerHTML]="des"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="plan-block-in">
            <div class="plan-block-hdr-main">
              <div class="plan-block-hdr">
                <div class="hdr-left">
                  <div class="icon-sec">
                    <img src="../../../../assets/images/enterprise-icon.svg" alt="">
                  </div>
                  <div class="content-sec">
                    <span>For Special Requests</span>
                    <p>Enterprise</p>
                  </div>
                </div>
              </div>
              <div class="hdr-dec">
                <p>Need more? Contact us to tailor the perfect solution for your agency or team. Includes
                  unlimited seats and reporting.</p>
              </div>
            </div>
            <div class="plan-price">
              <h1>Speak<sub>with an Expert</sub></h1>
              <p>
                <strong>Custom Pricing</strong>
                tailored to your needs
              </p>
            </div>
            <div class="plan-selection">
              <button class="btn_primary" (click)="conciergeModal()">Book a Demo</button>
            </div>
            <div class="plan-description">
              <p>Advanced tools for social business at scale</p>
              <div class="includes-point">
                <div class="includes-point-title">
                  <span>Enterprise includes
                    <br/>All Professional plan features,
                    <br/>as well as:
                  </span>
                </div>
                <div class="includes-point-list">
                  <ul>
                    <!--<li><strong>All Professional Plan Features</strong></li>-->
                    <li>Custom Users</li>
                    <li>Unlimited Social Profiles</li>
                    <li>Tailored ChatGPT Content Solutions</li>
                    <li>Advanced Team Collaboration Tools</li>
                    <li>Custom Roles and Permissions</li>
                    <li>Enterprise-Level Bulk Posting</li>
                    <li>Advanced Analytics and Reporting</li>
                    <li>Personalized Support and Onboarding</li>
                    <li>Dedicated Account Manager</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>



<!--------------------------------------->
<nz-modal [(nzVisible)]="isVisible" [nzTitle]="subscription === null ? 'Subscribe' : isSkipTrial ? 'Start Subscription' : getText() +' Subscription'"
          (nzOnCancel)="handleCancel()" nzClassName="subscribe_modal">
  <div *nzModalContent class="card-modal">
    <div class="addon-modal-header" style="display: none;">
      <p class="addon_name">{{selectedPlanName | titlecase}}</p>
      <p class="addon_price">${{selectedPlanAmount}} <span>/{{ sharedService.formattedName(subscriptionType) | titlecase}}</span></p>
    </div>
    <div class="checkout_main">
      <div class="checkout_left">
        <app-card-data [allCard]="allCard"></app-card-data>
        <app-add-card (getCardDetails)="getAllCard()"></app-add-card>
      </div>
      <div class="checkout_right">
        <div class="checkout_right_in">
          <div class="plan_dtls">
            <div class="pay_anual">
              <span>Summary</span>
            </div>
            <div class="plan_sub">
              <div class="plan_sub_dtl">
                <div class="plan_sub_dtl_in">
                  <p>{{selectedPlanName | titlecase}} {{ sharedService.formattedName(subscriptionType) | titlecase}}</p>
                  <p>${{selectedPlanAmount}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="coupn_code_main" *ngIf="isFirstPurchase">
            <div class="input_main">
              <input type="text" nz-input [(ngModel)]="couponCode" placeholder="Please enter coupon code here"
                     [disabled]="discountedAmount!=0" />
            </div>
            <div class="btn_main">
              <button class="btn_primary" nz-button nzType="primary" (click)="applyCouponCode()"
                      [class.disabled_while_loading]="couponCode==='' || discountedAmount">Apply</button>
            </div>
          </div>

          <div class="total_main">
            <div class="total_in" *ngIf="discountedAmount">
              <p>Discounted Amount</p>
              <p>${{discountedAmount}}</p>
            </div>
            <div class="total_in">
              <p><strong>Final Total</strong></p>
              <p><strong>${{selectedPlanAmount-discountedAmount}}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter class="subscription_footer">
    <button nz-button nzType="primary" type="submit" [disabled]="allCard.length === 0 || isSubscribing"
            class="btn-subscribe" (click)="subscribe()"> {{subscription === null ? 'Subscribe' : isSkipTrial ? 'Start Subscription' : getText() +' Subscription'}}
      <ng-container *ngIf="isSubscribing">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>
  </div>
</nz-modal>

<nz-modal nzClassName="down_member_modal" [(nzVisible)]="isSocialVisible"
          nzTitle="Select your social accounts which you want to keep after downgrade membership"
          (nzOnCancel)="handleSocialModalCancel()">
  <div *nzModalContent class="card-modal">
    <nz-alert nzType="error"
              nzMessage="*All other account will be removed and its scheduled post will be canceled/deleted"></nz-alert>
    <div class="bottom_space"></div>
    <div class="user_info_collapse_wrp">
      <perfect-scrollbar style="max-height: calc(100vh - 420px);">
        <nz-collapse>
          <nz-collapse-panel *ngFor="let account of allAccounts | keyvalue" nzHeader="{{account.key}}" nzActive="true">
            <ng-container *ngIf="account.value.length > 0;else noAccounts">
              <div class="down_member_list">
                <ng-container *ngFor="let account_list of account.value">
                  <label nz-checkbox [(ngModel)]="account_list.is_checked" nzValue="{{account_list.value}}"
                         (ngModelChange)="selectPlatformAccount()">
                    <div class="user_info_main">
                      <div class="user_img">
                        <app-image-preview [imageUrl]="account_list.image" [class]="'account-list-image'"
                                           [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="user_dtl">
                        <span>{{account_list.label}}</span>
                      </div>
                    </div>
                  </label>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #noAccounts>
              <p>No Accounts Found</p>
            </ng-template>
          </nz-collapse-panel>
        </nz-collapse>
      </perfect-scrollbar>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="primary" type="button" class="btn-subscribe" (click)="downgradeSelection()">Downgrade</button>
  </div>
</nz-modal>

<nz-modal [(nzVisible)]="isConcierge" nzTitle="Book a Demo" nzClassName="o4a_extra_form_modal" [nzFooter]="null" (nzOnCancel)="isConcierge = false">
  <ng-container *nzModalContent>
    <div class="o4a_extra_form" [formGroup]="conciergeForm">
      <div class="form-group">
        <nz-checkbox-wrapper (nzOnChange)="concierge_option($event)">
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Affiliate/Referral Partnership">Affiliate/Referral Partnership</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Automated Responses (e.g. computer/bot automated answers to common questions)">Automated Responses (e.g. computer/bot automated answers to common questions)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Additional Social Accounts">Additional Social Accounts</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Additional Users">Additional Users</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="A.I. Generated Art Content">A.I. Generated Art Content</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Integration/API connection to additional tools">Integration/API connection to additional tools</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Integration to your internal report system">Integration to your internal report system</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Custom reports generated by O4A">Custom reports generated by O4A</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Connect to another social media platform (e.g. Mastodon, Twitch, Quora, etc.)">Connect to another social media platform (e.g. Mastodon, Twitch, Quora, etc.)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="CRM integration (Monday, Hubspot, Salesforce, etc.)">CRM integration (Monday, Hubspot, Salesforce, etc.)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Dedicated Customer Success Manager (Human Social Media Manager for your account)">Dedicated Customer Success Manager (Human Social Media Manager for your account)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Content generation by our team">Content generation by our team</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Custom analysis of results">Custom analysis of results</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Specialized Meta Data Analysis of Topic Area">Specialized Meta Data Analysis of Topic Area</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Industry Research and Gap Analysis">Industry Research and Gap Analysis</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Video production services">Video production services</label>
        </nz-checkbox-wrapper>
      </div>

      <div class="form-group">
        <nz-form-item>
          <nz-form-label nzFor="email">Email</nz-form-label>
          <nz-input-group>
            <nz-form-control [nzErrorTip]="emailError">
              <input nz-input formControlName="email" id="email2" [disabled]="isDisabled" />
              <ng-template #emailError let-control>
                <ng-container *ngIf="control.hasError('required')">
                  This Email Field is required!
                </ng-container>
                <ng-container *ngIf="control.hasError('email')">
                  Please Enter Valid Email!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-input-group>
        </nz-form-item>
      </div>

      <div class="form-group">
        <label>Notes</label>
        <textarea nz-input nzAutosize formControlName="notes" [disabled]="isDisabled"></textarea>
      </div>
      <div class="o4a_extra_form_action">
        <button class="btn_primary_outline" (click)="isConcierge = false" [disabled]="isDisabled">Cancel</button>
        <button class="btn_primary" (click)="saveConcierge()" [disabled]="isDisabled">
          <span>Save</span>
          <span nz-icon nzType="loading" nzTheme="outline" *ngIf="isLoadning"></span>
        </button>
      </div>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isContactSubmitModal" nzClassName="contact_conf_modal" [nzFooter]="null" (nzOnCancel)="isContactSubmitModal = false">
  <ng-container *nzModalContent>
    <div class="reachus_layout">
      <img src="../../../../assets/images/contact_submit.png" alt="">
      <h2>Contact request sent</h2>
      <p>"Thanks for reaching out! We'll be in touch soon."</p>
    </div>
  </ng-container>
</nz-modal>
