import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AutoLogin, ChangePassword, Login, Logout, UpdateAccountPopup, UpdateImageCropPopup, UpdateProfile, UpdateUser } from '../actions/auth.action';

export interface AuthStateModel {
  user: {
    meta_data?: {
      image_crop_popup;
      account_popup;
    };
  } | null;
  token: string | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: {
      meta_data: {
        image_crop_popup: 1,
        account_popup : 0
      }
    },
    token: null
  }
})
@Injectable()

export class AuthState {
  @Selector()
  static user(state: AuthStateModel): any {
    return state.user;
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return <string>state.token;
  }

  @Selector()
  static imageCropPopup(state: AuthStateModel): boolean {
    return state.user?.meta_data?.image_crop_popup ?? 1;
  }

  @Action(Login)
  login(authState: StateContext<AuthStateModel>, action: Login): void {
    authState.patchState({
      token: action.token,
      user: action.payload
    });
  }

  @Action(Logout)
  logout(authState: StateContext<AuthStateModel>): void {
    authState.setState({
      token: null,
      user: null
    });
  }

  @Action(AutoLogin)
  autologin(authState: StateContext<AuthStateModel>, action: AutoLogin): void {
    authState.patchState({
      user: action.  payload
    });
  }

  @Action(UpdateUser)
  UpdateUser(authState: StateContext<AuthStateModel>, action: UpdateUser): void {
    authState.patchState({
      user: action.payload
    });
  }

  @Action(ChangePassword)
  changePassword(authState: StateContext<AuthStateModel>, action: ChangePassword): void {
    authState.patchState({
      user: action.payload
    });
  }

  @Action(UpdateProfile)
  updateProfile(authState: StateContext<AuthStateModel>, action: UpdateProfile): void {
    authState.patchState({
      user: action.payload
    });
  }

  @Action(UpdateImageCropPopup)
  updateImageCropPopup(authState: StateContext<AuthStateModel>, action: UpdateImageCropPopup): void {
    const state = authState.getState();
    authState.patchState({
      user: {
        ...state.user,
        meta_data: {
          ...state.user?.meta_data,
          image_crop_popup: action.payload
        }
      }
    });
  }

  @Action(UpdateAccountPopup)
  UpdateAccountPopup(authState: StateContext<AuthStateModel>, action: UpdateAccountPopup): void {
    const state = authState.getState();
    authState.patchState({
      user: {
        ...state.user,
        meta_data: {
          ...state.user?.meta_data,
          account_popup: action.payload
        }
      }
    });
  }
}
